.otherLandingContainer {
  padding: 40px 0;
  margin: 30px 0;
}

.title {
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 12px;
    color: #ffffff;
    font-family: 'Crimson Text', serif;
    font-style: italic;
    @media (max-width: 768px) {
      font-size: 32px;
    }
  }

  p {
    font-size: 18px;
    color: #666;
  }
}

.optionsContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.optionCard {
  background: #1e1e1e;
  border-radius: 10px;
  overflow: hidden;
  width: 396px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  cursor: pointer;
  height: 502px;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 168px;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

.imageWrapper {
  height: 286px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 768px) {
    width: 40%;
    height: auto;
    min-height: 120px;
  }
}

.optionContent {
  padding: 20px;

  h3 {
    color: #ffffff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  p {
    margin-top: 30px;
    color: #ffffff;
    font-size: 16px;
    line-height: 1.5;
  }

  @media (max-width: 768px) {
    width: 60%;
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 600;
    }

    p {
      font-size: 13px;
      line-height: 1.3;
      margin-top: 5px;
      margin-bottom: 30px;
      opacity: 0.8;
    }
  }
}

.tryNow {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;

    span {
      margin-right: 5px;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

